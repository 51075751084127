import {
  INICIO_FORM,
  EXITO_FORM,
  ERROR_FORM,
} from "../types/types";
import clienteAxios from "../config/axios";
const initialState = {
  loading: false,
  Mensaje: "",
  enviado: false,
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INICIO_FORM: {
      return {
        ...state,
        loading: action.payload,
        enviado: false,
      };
    }

    case EXITO_FORM:
      return {
        ...state,
        loading: false,
        Mensaje: action.payload,
        enviado: true,
      };
    case ERROR_FORM:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
}

export function EnviarDataSolicitud(Form) {
  debugger
  return (dispatch) => {
    clienteAxios
      .post(`mywsmobil/api/SolicitudUsuarioWeb/SolicitarInfo`, Form)
      .then((Respuesta) => {
        if (Respuesta.data.Resultado) {
          dispatch(EnviadoExito(Respuesta.data.Mensaje));
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };
}

export function LevantarBackdrow(value) {
  return (dispatch) => {
    dispatch(EnviarInicio(value));
  };
}
export const EnviarInicio = (data) => ({
  type: INICIO_FORM,
  payload: data,
});

export const EnviadoExito = (data) => ({
  type: EXITO_FORM,
  payload: data,
});

